@font-face {
  font-family: "Acherusgrotesque Regular";
  font-weight: 100;
  src: url("https://jaam-fonts.s3.amazonaws.com/acherusgrotesque-regular.otf");
}

@font-face {
  font-family: "Acherusgrotesque Regita";
  src: url("https://jaam-fonts.s3.amazonaws.com/acherusgrotesque-regita.otf");
}

@font-face {
  font-family: "Gilroy Light";
  src: url("https://jaam-fonts.s3.amazonaws.com/gilroy-light.otf");
}

@font-face {
  font-family: "Gilroy Extrabold";
  src: url("https://jaam-fonts.s3.amazonaws.com/gilroy-extrabold.otf");
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  src: url("https://jaam-fonts.s3.amazonaws.com/HelveticaNeue.ttc");
}

@font-face {
  font-family: "Helvetica Neue Desk Interface";
  font-style: normal;
  font-weight: 400;
  src: url("https://jaam-fonts.s3.amazonaws.com/HelveticaNeueDeskInterface.ttc");
}

body {
  margin: 0;
  font-size: 1rem;
  font-family: "Acherusgrotesque Regular";
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

.AcherusgrotesqueRegular {
  font-family: "Acherusgrotesque Regular";
}

.GilroyLight {
  font-family: "Gilroy Light";
}

.GilroyExtrabold {
  font-family: "Gilroy Extrabold";
}

.HelveticaNeue {
  font-family: "Helvetica Neue";
}

.mt-5,
.my-5 {
  margin-top: 4rem !important;
}

/* Rectangle 81 */
.upgradeac {
  margin-top: -36px !important;
  width: 100%;
  height: 59px;
  border-radius: 10px !important;
  background: #FFEBC4 !important;
}

.counterbox {
  margin-top: 17px;
  /* left:300px; */
}

.statusData {
  margin-top: 17px;
  /* padding-left: 420px; */
  /* top:90px; */
  font-style: normal;
  font-weight: normal;
  /* font-size: 16px; */
  line-height: 23px;
  color: #721C47;
  /* text-align: right; */
}

.btn-primary {
  margin-top: 10px;
  width: 216px;
  height: 34px;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  background: #5D3EA8 !important;
  border-radius: 30px !important;
}

/*********   BANNER   **********************/

.banner {
  background-size: cover;
  height: 85vh !important;
}

@media only screen and (max-width: 600px) {
  .upgradeac {
    height: 120px;
  }

  .statusData {
    margin-right: 40px;
  }

  .mt-5,
  .my-5 {
    margin-top: 6rem !important;
  }

  .btn-primary {
    width: fit-content;
    margin-left: 100px;
  }
}

@media only screen and (min-width: 600px) {
  .upgradeac {
    height: 65px;
  }

  .btn-primary {
    width: fit-content;
  }
}

@media screen and (max-width: 1100px) and (min-width: 992px) {
  .upgradeac {
    margin-left: 300px;
    height: 59px;
    text-align: left !important;
  }

  .upgradenow {
    margin-left: 100px;
    margin-right: 25px;
  }

  .btn-primary {
    width: 216px;
  }
}

@media only screen and (min-width: 1200px) {
  .upgradeac {
    margin-left: 350px !important;
    height: 59px;
  }

  .upgradenow {
    margin-left: 100px;
    margin-right: 25px;
  }

  .btn-primary {
    width: 216px;
  }
}

/* less than 500 */
@media (max-width: 500px) {
  .banner h1 {
    font-family: "Gilroy Extrabold";
    font-size: 34px;
  }

  .banner p {
    font-size: 24px;
  }

  .carousel-item-body {
    margin-top: 15vh;
  }

  .banner1 {
    background-image: url("../assets/mobilebanner1.png");
  }

  .banner2 {
    background-image: url("../assets/mobilebanner2.png");
  }

  .banner3 {
    background-image: url("../assets/mobilebanner3.png");
  }

  .ProductFeatureBanner {
    background-image: url("../assets/mobileproductfeature4.png");
    background-position: top 150px left -50px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 75vh;
    width: 100%;
    display: block;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  float: none;
}

.dropdown-menu-right {
  right: 30px;
  left: auto;
}

.dd_pointer {
  cursor: pointer;
}

.dd-text {
  text-decoration: none;
  color: #5D3EA7;
  white-space: nowrap !important;
  padding: 10px 10px 10px 10px;
}

li:hover {
  text-decoration: none;
}

.dropdown-menu {
  position: absolute;
  text-align: left;
  right: -45px;
  top: 63px;
  width: 260px;
  height: 117px;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  z-index: 1;
  border-radius: 0%;
}

.capitalize {
  text-transform: capitalize;
}

.vl {
  border-left: 1px solid rgb(72, 87, 72);
  height: 30px;
  position: absolute;
  left: 86%;
  margin-left: -3px;
  top: 20px;
}

.pagenotfound_box {
  padding-left: 200px;
}

.pagenotfound_text {
  position: absolute;
  width: 180px;
  height: 123px;
  left: 10px;
  top: 50px;
  font-style: normal;
  font-weight: 800;
  font-size: 100px;
  line-height: 123px;
  /* identical to box height */
  color: #908C9A;
}

.pagenotfound_head {
  width: 135px;
  height: 67px;
  padding-left: 100px;
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 46px;
  line-height: 67px;
  /* identical to box height */
  text-align: center;
  color: #000000;
}

.pagenotfound_body {
  width: 292px;
  height: 40px;
  padding-left: 100px;
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  /* or 111% */
  text-align: center;
  color: #000000;
}

.pagenotfound_btn {
  background: #5D3EA7;
  border-radius: 30px;
  margin-left: 300px;
  font-weight: 200;
}

@media (max-width: 500px) {
  .pagenotfound_box {
    padding-left: 0px;
    height: 300px;
    width: 300px;
  }

  .pagenotfound_text {
    width: 80px;
    height: 50px;
    left: -20px;
    top: 130px;
    font-weight: 800;
    font-size: 80px;

  }

  .pagenotfound_head {
    width: 135px;
    padding-left: 0px;
    margin-top: -70px;
  }

  .pagenotfound_body {
    width: 292px;
    height: 40px;
    padding-left: 0px;
    margin-top: 20px;

  }

  .pagenotfound_btn {
    margin: 20px 20px 20px 65px;
  }
}

/* 500 to 767 */
.mute {
  cursor: pointer;
  height: 50px;
  width: 50px;
  position: absolute;
  top: auto;
  left: 20px;
  border: 0px solid #000;
  background-size: cover;
  margin-top: 20px;
}

/* 500 to 767 */
@media (max-width: 500px) {
  .mute {
    height: 25px;
    width: 25px;
    right: 20px;
  }
}

@media (min-width: 500px) {

  .banner h1 {
    font-family: "Gilroy Extrabold";
    font-size: 34px;
  }

  .mute {
    height: 25px;
    width: 25px;
  }

  .banner p {
    font-size: 24px;
  }

  .carousel-item-body {
    margin-top: 15vh;
  }

  .banner1 {
    background-image: url("../assets/mobilebanner1.png");
  }

  .banner2 {
    background-image: url("../assets/mobilebanner2.png");
  }

  .banner3 {
    background-image: url("../assets/mobilebanner3.png");
  }

  .ProductFeatureBanner {
    background-image: url("../assets/mobileproductfeature4.png");
    background-position: top 150px left -50px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 75vh;
    padding-top: 40px;
    display: block;
  }
}

/* 767 to 991 */
@media (min-width: 767.98px) {
  .banner h1 {
    font-family: "Gilroy Extrabold";
    font-size: 34px;
  }

  .banner p {
    font-size: 24px;
  }

  .carousel-item-body {
    margin-top: 20vh;
  }

  .banner1 {
    background-image: url("../assets/banner1.png");
  }

  .banner2 {
    background-image: url("../assets/banner2.png");
  }

  .banner3 {
    background-image: url("../assets/banner3.png");
  }

  .ProductFeatureBanner {
    background-image: url("../assets/productfeature4.png");
    background-position: top 50px left 100px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 75vh;
    width: 100%;
    padding-top: 40px;
    display: block;
  }
}

/* above 991 */
@media (min-width: 991.98px) {
  .banner h1 {
    font-family: "Gilroy Extrabold";
    font-size: 44px;
  }

  .banner p {
    font-size: 36px;
  }

  .carousel-item-body {
    margin-top: 25vh;
  }

  .banner1 {
    background-image: url("../assets/banner1.png");
  }

  .banner2 {
    background-image: url("../assets/banner2.png");
  }

  .banner3 {
    background-image: url("../assets/banner3.png");
  }

  .ProductFeatureBanner {
    background-image: url("../assets/productfeature4.png");
    background-position: top 50px left 100px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 75vh;
    width: 100%;
    padding-top: 40px;
    display: block;
  }
}

.banner_bg_stars {
  background-image: url("../assets/banner_bg_stars.svg");
  background-size: cover;
  min-height: 30vh !important;
}

.carousel-item-body-15 {
  margin-top: 20vh;
  margin-bottom: 10vh;
  font-size: 20px;
}

.banner_about_bg {
  background-image: url("../assets/about_bg.svg");
  background-size: cover;
  min-height: 30vh !important;
}

.background {
  background-image: url("../assets/background.png");
  background-repeat: no-repeat;
  background-attachment: fixed !important;
  background-size: cover;
  min-height: 78vh;
}

.movedown {
  margin-top: 25vh;
}

@media (min-width: 1025px) {
  .PartnershipBanner {
    background-image: url("../assets/Partnership.png") !important;
    background-repeat: no-repeat !important;
    /* background-position-y: 80px !important; */
    background-size: cover !important;
    min-height: 85vh !important;
  }

  .movedown {
    margin-top: 52.2vh !important;
  }
}

@media screen and (max-width: 1025px) and (min-width: 768px) {
  .PartnershipBanner {
    background-image: url("../assets/Partnership.png") !important;
    background-repeat: no-repeat !important;
    background-position-y: 80px !important;
    background-size: cover !important;
    min-height: 25vh !important;
  }

  .movedown {
    margin-top: 17vh !important;
    margin-bottom: -1vh !important;
  }
}

@media screen and (max-width: 700px) and (min-width:200px) {
  .PartnershipBanner {
    background-image: url("../assets/Partnership.png");
    background-repeat: no-repeat !important;
    background-position-y: 80px !important;
    background-position: center;
    background-size: cover !important;
    min-height: 80vh !important;
  }

  .movedown {
    margin-top: 45vh !important;
  }
}

/*********   BANNER   **********************/

.vh-90 {
  height: 90vh !important;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  outline-style: none;
}

.nav-link {
  color: #212529;
  text-decoration: none;
  padding: 10px 0px;
  margin: 0px 5px;
  display: inline-block;
  position: relative;
  opacity: 1;
  /* font-family: Acherus Grotesque; */
}

.nav-link:hover {
  opacity: 1;
}

/* .nav-link::before {
  transition: 300ms;
  height: 3px;
  content: "";
  position: absolute;
  background-color: #6610f2;
}

.nav-link-fade-up::before {
  width: 90%;
  bottom: 5px;
  opacity: 0;
}

.nav-link-fade-up:hover::before {
  bottom: 1px;
  opacity: 1;
} */

.active {
  color: #6610f2;
}

.bg-default {
  background-color: #ebebf5 !important;
}

.text-purple {
  color: #5d3ea8 !important;
}

.text-6D6D74 {
  color: #6d6d74 !important;
}

.text-B399F1 {
  color: #b399f1 !important;
}

.text-black {
  color: #000000 !important;
}

.text-525260 {
  color: #525260 !important;
}

.bg-orange {
  background-color: #fd7900 !important;
}

.text-orange {
  color: #fd7900 !important;
}

.horizontal {
  border-top: solid 1px;
  border-color: #b9b9b9 !important;
}

.vertical {
  border-left: solid 2px #b9b9b9;
  padding: 0px 0px 0px 20px;
}

.verticalBlack {
  border-left: solid 2px #333333;
  padding: 0px 0px 0px 0px;
}

.xxlarge {
  font-size: 175%;
}

.xlarge {
  font-size: 150%;
}

.large {
  font-size: 125%;
}

.medium {
  font-size: 100%;
}

.xsmall {
  font-size: 70%;
  font-weight: 400;
}

.select-purple {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("../assets/sorted.png") no-repeat right 1rem center/20px 25px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.accordion .collapse-header:after {
  font-family: "FontAwesome";
  content: "\f106";
  float: right;
  margin-right: 2em;
}

.accordion .collapse-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f107";
  float: right;
  margin-right: 2em;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #5d3ea7;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: black;
  background-color: white;
}

.over {
  position: absolute;
  overflow: visible;
  left: 16px;
  top: -3px;
  z-index: 4;
}

.badge-pill {
  padding: 0.7em 1em 0.7em 1em;
  border-radius: 10rem;
  white-space: normal;
}

.badge-gray {
  color: #4f4f4f;
  background-color: #d3d6d8;
}

.card {
  border-radius: 20px;
  background-clip: border-box;
  background-color: rgb(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.card-circle {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 400px;
  height: 350px;
  width: 350px;
  display: table;
}

.carousel-control-prev,
.carousel-control-next {
  width: 8%;
}

.imagesize {
  height: 120px;
  width: 120x;
}

.imagesize:hover {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.imageDice {
  position: relative;
  border-radius: 10px;
  transform: rotate(45deg);
  overflow: hidden;
  transform: matrix(0.54, 0.84, -0.85, 0.53, 0, 0);
}

.imageDice>img {
  max-width: 100%;
  transform: rotate(-55deg) scaleX(1.6) scaleY(1.6);
}

.imageDice:hover,
.imagecontainer:hover {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #fd7900 !important;
}

.imageDice-theme {
  cursor: pointer;
  height: 110.62px;
  width: 110.62px;
  position: relative;
  border-radius: 30px;
  transform: rotate(52.79deg);
  overflow: hidden;
  transform: matrix(0.54, 0.84, -0.85, 0.53, 0, 0);
}

.imageDice-theme>img {
  max-width: 100%;
  max-height: 100%;
  transform: rotate(-55deg) scaleX(1.5) scaleY(1.5);
}

.imageDice-theme:hover,
.imagecontainer:hover {
  border: 6px solid #5D3EA7;
}

.selectedDice {
  border: 6px solid #5D3EA7;
}

.dice-container {
  position: relative;
}

.circle-theme {
  position: absolute;
  top: -10px;
  left: 95px;
  width: 40px;
  height: 40px;
  background-color: #fb7900;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-theme .theme-label {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16.48px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  width: 15.52px;
  height: 15.52px;
  left: -10px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #5D3EA7;
}

input:focus+.slider {
  box-shadow: 0 0 1px #5D3EA7;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.adjustLeft:before {
  left: 0 !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.add:before {
  border-radius: 50%;
}

.slider.round:before {
  border-radius: 50%;
}

mark {
  background-color: yellow;
}

.container-margin {
  margin-top: 200px;
}

.privacypolicy-container-margin {
  margin-top: 150px;
}

.container-decoration {
  padding-top: 120px;
  padding-bottom: 40px;
}

.container-decoration-top {
  padding-top: 120px;
}

body {
  height: 100vh;
}

.content-center {
  justify-content: center;
}

.start-circle {
  margin-top: 5rem !important;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.4rem 1.25rem;
  background-color: #fff;
  border: 0px solid rgba(0, 0, 0, 0.125);
}

/*  Margin n10 ******************************/
.mt-n10,
.my-n10 {
  margin-top: -19rem !important;
}

@media (min-width: 576px) {

  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -19rem !important;
  }
}

@media (min-width: 768px) {

  .mt-md-n10,
  .my-md-n10 {
    margin-top: -19rem !important;
  }
}

@media (min-width: 992px) {

  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -19rem !important;
  }
}

@media (min-width: 1200px) {

  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -19rem !important;
  }
}

/*  Margin n10 ******************************/
.home_videocontainer {
  margin-bottom: -10px;
}

.pagenotfound {
  margin-top: 60px;
}

@media (max-width: 500px) {
  .pagenotfound {
    margin-top: 10px;
  }
}

/* Web audit : About page changes */
#aboutjaam {
  color: #ffffff;
}

/* NavBar changes in navbar*/
#join:hover {
  text-decoration: underline;
}

#signin:hover {
  text-decoration: underline;
}

#myaccount:hover {
  text-decoration: underline;
}

#tryfor {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
}

#cowntact:hover {
  color: #5D3EA7;
}

#partner:hover {
  color: #5D3EA7;
}

#priicing:hover {
  color: #5D3EA7;
}

#profeat:hover {
  color: #5D3EA7;
}

#serviceson:hover {
  color: #5D3EA7;
}

/* For tab(new-line) in mobile devices */
@media (max-width: 576px) {
  #new-line-mobile::before {
    content: "\a";
    white-space: pre;
  }
}

/*Product feature image changes*/
@media (min-width: 1025px) {
  .width-height-change-on-pc {
    width: 128% !important;
    height: 120% !important;
  }
}

.showxs {
  display: none;
}

@media (max-width: 576px) {
  .full-image {
    height: 100%;
    width: 100%;
  }

  .showxs {
    display: inline-block;
  }

  .showmd {
    display: none;
  }
}

/*Partnership form changes*/
.movedown {
  margin-top: 46vh;
}

.font-bolder {
  font-weight: 800;
  font-size: 48px;
  line-height: 50px;
}

.changebackground {
  background: linear-gradient(to bottom, #112935, #6C869D 50%);
}

/*Support page CSS*/

.support-navigation .btn-group .landing-mobile-nav {
  width: 115%;
  margin-left: -1.8rem;
}

.support-navigation .btn-group .landing-mobile-nav:focus {
  width: 115%;
  margin-left: -1.8rem;
  border: none;
}

.support-navigation .select-main-bg {
  background-color: #5D3EA8;
  height: 50px !important;
  width: 90vw;
}

.support-navigation .roboto-large-white {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px;
  color: #EEEEEE !important;
}

.support-navigation .dropdown-menu.nav {
  display: none !important;
}

.support-navigation .dropdown-menu.nav.show {
  display: inline-block !important;
  padding-right: 10px !important;
  width: 102% !important;
  height: 580px !important;
  transform: translate3d(16px, 47px, 0px) !important;
  left: -23px !important;
}

.support-navigation .caret {
  padding-right: calc(15.9em - 6.9em) !important;
}

.anytime {
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  color: #333333;
}

.horizlinee {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  opacity: 0.3;
}


.verticalll {
  border-left: solid 1px #C4C4C4;
  padding: 0px 0px 0px 20px;
}

.modal-dialog {
  max-width: 850px;
  margin: 1rem auto;
}

.close {
  font-size: 30px;
}

.joinameet {
  font-family: "Acherus Grotesque", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #6D6D74;

}

.instructions {
  font-family: "Acherus Grotesque";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #000000;

}

.common {
  font-family: "Acherus Grotesque";
}

#text {
  text-align: left;
}

.text-blackk {
  color: #000000 !important;
  padding-left: 10px;
}

.text-blackk.active {
  color: #5D3EA7 !important;
  background: rgba(255, 255, 255, 0.5) !important;
}

.fontbold {
  font-weight: 1000;
}

.image57align {
  margin-top: -90px;
}

.collapse-header.collapsed {
  background-color: transparent;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.collapse-header {
  background-color: #f7f7f7;
  padding-top: 15px !important;
  /* font-family: "Acherus Grotesque"; */
}

.accordionanswer {
  background-color: #f7f7f7;
  margin-bottom: -10px;
  /* font-family: "Acherus Grotesque"; */
}

.collapse-header.collapsed:hover {
  background-color: transparent;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.collapse-header:hover {
  padding-top: 15px !important;
  background-color: transparent;
}

.accordionanswer:hover {
  background-color: transparent;
}

.fullaccordion:hover {
  background-color: #f7f7f7;

}

.horizontal-accordion {
  border-top: solid 1px;
  margin-left: 0px;
  border-color: #b9b9b9 !important;

}

/* #total-opacity {
  opacity: 0.3;
}

#answer-opacity {
  opacity: 0.9;
}

.faq-heading {
  font-weight: 800;
  font-size: 35px;
  line-height: 20px;
  color: #333333;
}

.faq-italic-text {
  font-size: 15px;
} */

@media (max-width: 576px) {
  #tex {
    text-align: center;
  }

  #giveleft {
    margin-top: 25px;
  }
}

/*Partnership checkbox size*/
.larger-checkbox {
  width: 15px;
  height: 15px;
}

/*Edit Button Changes in Landing Page*/
.move-edit-button {
  margin-left: 97px;
}

@media (max-width: 576px) {
  .move-edit-button {
    margin-left: 68px !important;
  }
}

@media (max-width: 768px) {
  .move-edit-button {
    margin-left: 80px;
  }
}

/*Error Messages*/

.errormessage {
  color: red;
  font-size: 13px;
}


/*Feedback Form Changes*/
.e-upload {
  border: none !important;
  border-radius: 30px !important;
  width: 100 !important;
  background-color: #FFFFFF !important;
}

.e-file-type {
  margin-right: 820px;
}

.e-file-size {
  margin-right: 675px;
}

.e-upload-files {
  border-top: none !important;
}

.e-upload-file-list {
  border-bottom: none !important;
}

.newupload {
  border: 1px solid #ced4da !important;
  width: 100;
  border-radius: 1.3rem !important;
  background-color: #FFFFFF !important;
}

.yourfiles {
  font-family: "Acherus Grotesque", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #908C9A;
}

.drag {
  font-family: "Acherus Grotesque", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #333333;
}

.e-icons {
  color: #5D3EA7 !important;
}

.e-upload .e-upload-files .e-file-remove-btn.e-icons::before {
  visibility: hidden;
}

.e-file-remove-btn.e-icons::after {
  visibility: visible !important;
  margin-top: -15px;
  margin-right: 799px;
  content: '\e94a' !important;
}

.e-btn {
  visibility: hidden !important;
  text-transform: none !important;
}

.e-file-drop {
  visibility: hidden !important;
}

.e-file-drop::before {
  visibility: visible !important;
  content: "your files";
  margin-right: -100px;
  margin-left: -18px;
  font-family: "Acherus Grotesque", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #908C9A;
}

.e-btn::before {
  content: "or ";
  visibility: visible;
  font-family: "Acherus Grotesque", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #908C9A;
  margin-right: -50px;
}

.e-btn::after {
  content: "browse" !important;
  visibility: visible;
  font-family: "Acherus Grotesque", sans-serif;
  font-style: normal;
  margin-left: -7px;
  font-weight: normal;
  font-size: 18px;
  text-decoration: underline;
  line-height: 26px;
  text-align: center;
  color: #5D3EA7;
}

/*Star Rating*/
.MuiRating-root {
  color: #5D3EA7 !important;
  text-shadow: #5D3EA7;
  font-size: 2.99rem !important;
}

@media (max-width: 576px) {
  .e-file-type {
    margin-right: 800px;
  }

  .e-file-size {
    margin-right: 180px;
  }

  .newupload {
    border: 1px solid #ced4da !important;
    width: 100%;
    border-radius: 1.3rem !important;
    background-color: #FFFFFF !important;
  }

  .e-file-remove-btn.e-icons::after {
    visibility: visible !important;
    margin-top: -15px;
    content: '\e94a' !important;
  }

  /*Star Rating*/
  .MuiRating-root {
    color: #5D3EA7 !important;
    text-shadow: #5D3EA7;
    font-size: 2.99rem !important;
  }
}

/*service*/
/* .servicebanner {
  background-image: url("../assets/Bannerimg.jpg");
  background-repeat: no-repeat;
  background-position-x: -50px;
  background-position-y: 170px;
  background-size: cover;
  min-height: 100vh;
} */

/* .service-btn {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  background-color: #F7F9FC;
  font-size: 16px;
  line-height: 20px;
  color: #5D3EA6;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px thick #5D3EA6
} */

.Joinameetunorderlist {
  margin: 0;
  padding: 0;
}

.joinameetspacing {
  text-indent: -0.1em;
  padding-left: 0.5em;
}

/* SingleProviderModal Changes */
/* .service-provider-text {
  text-transform: capitalize !important;
} */

@media only screen and (min-device-width : 490px) and (max-device-width : 1000px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 2) {
  #service-provider-proceed-btn-alignment {
    margin-left: 12px !important;
    margin-top: 0px !important;
  }
}

.service-provider-proceed-btn {
  font-family: "Gilroy Extrabold", sans-serif;
  /* font-weight: 800;
  font-size: 18px; */
  background-color: #FFFFFF;
  color: #5D3EA8 !important;
  padding: 8px 35px !important;
  cursor: pointer !important;
  border-radius: 30px;
  border: 1.5px solid #5D3EA8;
}

.service-provider-dismiss-btn {
  font-family: "Gilroy Extrabold", sans-serif;
  /* font-weight: 800;
  font-size: 18px; */
  background-color: #5D3EA8;
  color: #FFFFFF !important;
  padding: 8px 35px !important;
  cursor: pointer !important;
  border-radius: 30px;
  border: 1.5px solid #5D3EA8;
}

.existing-email-btn {
  font-family: "Gilroy Extrabold", sans-serif;
  /* font-weight: 800;
  font-size: 18px; */
  background-color: #5D3EA8;
  color: #FFFFFF !important;
  padding: 8px 35px !important;
  width: 200px !important;
  cursor: pointer !important;
  border-radius: 30px;
  border: 1.5px solid #5D3EA8;
}

.existing-email-home-btn {
  font-family: "Gilroy Extrabold", sans-serif;
  /* font-weight: 800;
  font-size: 18px; */
  background-color: none;
  color: #5D3EA8 !important;
  padding: none !important;
  cursor: pointer !important;
  border-radius: none;
  border: none;
}

.modal-welcome-text {
  font-style: normal;
  /* font-weight: normal;
  font-size: 22px; */
  line-height: 27px;
  color: #6D6D74;
}

.cancel_hidden {
  display: none;
}

.cancel_visible {
  display: block;
}

.fileupload {
  display: inline;
  vertical-align: top;
  text-align: center;
}

.fileupload_left {
  float: left;
  margin-left: 12rem;
}

body.modal-open .bg {
  filter: blur(5px);
}

.modal-header {
  border-bottom: none;
  padding-bottom: 3px;
}

/* .expandDialog {
  box-shadow: 0 8px 6px -6px black;
  position: static;
  left: 20%;
  top: 10%;
  height: fit-content;
  width: 700px;
}

.expandImage {
  height: fit-content;
  width: 700px;
} */

@media screen and (max-width: 600px) {
  .fileupload_left {
    float: left;
    margin-left: auto;
  }
}

/* Services page rework */
@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .display-only-on-pc {
    display: inline-block;
  }

  .display-only-on-tablet {
    display: none;
  }
}

@media screen and (max-width: 1199px) and (min-width: 576px) {
  .display-only-on-tablet {
    display: inline-block;
  }

  .display-only-on-pc {
    display: none;
  }
}

@media screen and (max-width: 575px) and (min-width: 200px) {
  .display-only-on-tablet {
    display: none;
  }

  .display-only-on-pc {
    display: none;
  }
}

@media only screen and (min-device-width : 200px) and (max-device-width : 770px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .display-only-on-mobile-landscape {
    display: block;
  }

  .display-only-on-landscape {
    display: none;
  }

  .display-only-on-pc-tablet {
    display: none;
  }
}

@media only screen and (min-device-width : 770px) and (max-device-width : 1200px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .display-only-on-landscape {
    display: inline-block;
  }

  .display-only-on-mobile-landscape {
    display: none;
  }
}
@import "../variables.scss";

/* common styles for global usage */
.mt {
    &__20{
        margin-top: 20px;        
    }

}

// COMMON SCSS CLASS FOR CONTENT SHOWING ONLY ON PC,TABLET & MOBILE 

.display-only-on-mobile {
    display: none;
  }
@media (max-width: $mobile-breakpoint ) {
  .display-only-on-mobile {
    display: inline-block !important;
  }
  .display-only-on-pc-tablet {
    display:none !important;
  }
 }

@import "../variables.scss";

//Landing-billing-tab
.width-100 {
  width: 100% !important;
}
.billing-tab-title {
    font-size: 25px;
    color: $black2;
  }
  @media (max-width:768px) {
    .billing-tab-title {
      font-size: 20px;
    }
}
  @media (max-width:576px) {
    .billing-tab-title {
      font-size: 18px !important;
    }
    .line-adjustment {
      margin-top: -4px !important;
    }
    .width_100 {
      width: 100% !important;
    }
}
  .biling-border-bottom.active {
      color: $purple1 !important;
      border-bottom-style: solid !important;
      border-bottom-width:medium !important;
      background-color:  transparent !important;
      border-color: $purple1 !important;
  }
  .biling-border-bottom {
      color: $gray1;
  }
  .billing-bottom-line {
      padding-bottom: 1%;
      border-bottom: 1px solid $gray3; 
  } 
  .line-adjustment {
      margin-top: -9.5px;
  }  
  
  //CARDBOX LAYOUT
  .boxLayout {
    border: 2px solid $black2 ; 
    border-radius: 20px !important;
    box-sizing: border-box;
    cursor: pointer;
    width: 230px;
    height: 132px;
  }
  .displaybox {
    cursor: default !important;
  }
  .boxLayoutactive {
    background-color: $layout-bg-color !important;
    border: 2px solid $orange1 !important;
    border-radius: 20px !important;
    box-sizing: border-box;
    cursor: pointer;
    width: 230px;
    height: 132px;
  }
  .boxLayoutinactive {
    background-color: transparent;
    border:1px solid $black1;
    border-radius: 20px !important;
    box-sizing: border-box;
    cursor: pointer;
    width: 230px;
    height: 132px;
  }
  .boxLayoutinactiveplan {
    opacity: .5;
    background-color: transparent;
    border:1px solid $black1;
    border-radius: 20px !important;
    box-sizing: border-box;
    cursor: pointer;
    width: 230px;
    height: 132px;
  }
//Box-plan Properties
  .box-plan {
    font-family: "Acherus Grotesque";
    font-style: normal;
    color: $black1;
    font-weight: 300;
    .box-plan-text {
      font-size: 18px;
      line-height: 23px;
    } 
    .box-plan-currency-value {
      font-size: 30px;
      font-weight: normal;
      line-height: 44px;
    } 
    .box-plan-per-value {
      font-size: 12px;
      line-height: 12px;
      color: $black2;
    }
    .box-plan-billed-yearly {
      font-size: 14px;
      line-height: 26px;
      color: $black2;
    }
    .percentdiscount {
      font-family: "Gilroy",sans-serif;
      color:#FFFFFF;
      font-weight: 800;
      font-size: 12px;
      background-color: $orange1;
      border-radius: 6px;
    }  
  }

//Get-billing & subscribe-checkout-changes
.billing {
  font-style: normal;
  font-weight: normal;
  color:$black1;
  line-height: 25px;
  .stripe-image, .tick-image {
    cursor:default !important;
  }
  .billing-title {
    font-family: "Gilroy",sans-serif;
    font-weight: 300;
    font-size: 22px;
  }
  .billing-plan-title {
    font-weight: 300;
    font-size: 22px;
  }
  // .billing-benefits-text {
  //   color: $gray1;
  // }
  // .billing-date-text {
  //   color: $blue1;
  // }
  .billing-horizontal-line {
    border-top: solid 1px;
    border-color: $gray3 !important;
    }
  .billing-white-bg-section {
    background-color: $common-white-bg-section;
  }
  .billing-list-text{
    font-size: 16px;
    color: $black1;
    cursor: default !important;
    li{
     padding-left: 1.8em;
     text-indent:-1.8em;
     list-style-type: none;
     padding: 0;
     margin: 0;
    }
  }  
  .billing-days-left-text {
    font-size: 16px;
  }
  // .billing-expiration-text {
  //   font-size: 14px;
  //   color:$gray1;
  // }
  .billing-plan-expired-text {
    font-family: "Gilroy",sans-serif;
    font-weight: 300;
    font-size: 22px;
  }
  .billing-reactivate-text {
    font-family: "Gilroy",sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: $black2;
  }

}

//Payment-Error Message
.payment-error-message{
  background-color: #F8D7DA !important;
  border:10px solid #F8D7DA !important;
  color: #721C47 !important;
  border-radius: 30px !important;
}
  
//Invoice Tab
  .Invoice-table-heading {
    font-weight: 800;
  }
  .Invoice-status {
    text-transform: capitalize;
  }
  .Invoice-table {
    display:table;
    width:100%;
  }
  .hscroll {
    overflow-x: auto !important;
  }
  .Invoice-table-content {
    font-weight: 300;
    font-size: 15px;
    color: $black2;
  }
  
// Payment success
  .payment-success {
    font-style: normal;
    font-weight: normal;
    color: $black2;
    line-height: 25px;
    .payment-success-message {
      font-size: 28px;
      line-height: 35px;
    }
    .payment-success-confirm {
      font-size: 22px;
      line-height: 27px;
    }
    .payment-success-plan {
      font-size: 20px;
      line-height: 25px;
    }
    .payment-success-dismiss-btn {
      font-family: "Gilroy Extrabold",sans-serif;
      font-weight: 800;
      font-size: 18px;
      color: $purple2;
      padding: 8px 69px;
      cursor: pointer !important;
      border-radius: 30px;
      border:1.5px solid $purple2;
    }
  }
  
  // //Payment Cancel
  // .payment-cancel {
  //   font-family: "Gilroy";
  //   font-style: normal;
  //   font-weight: normal;
  //   color:$black1;
  //   line-height: 25px;
  //   .payment-cancel-message {
  //     font-size:24px;
  //   }
  //   .payment-cancel-dismiss-btn {
  //     font-family: "Gilroy",sans-serif;
  //     font-weight: 800;
  //     font-size: 18px;
  //     color: $purple1;
  //     padding: 0.25rem 0.5rem;
  //     cursor: pointer !important;
  //     border-radius: 1.5rem;
  //     border:1px solid $purple1;
  //   }
  // }

  //Modals
// Billing Success & Cancel Modal css
.modal {
  backdrop-filter: blur(0.05rem);
  background-color: #01223770;
  .modal-content {
    border-radius: 1.3rem !important;
    border:none !important;
    .modal-body {
      .text-center {
        padding:.5rem;
      } 
      .btn-cancel {
        border-radius: 30px;
        // width: 6rem !important;
        padding: 8px 69px;
      }
    } 
  }
}
// payment-cancel-modal
.payment-cancel {
  font-weight: normal;
  font-style: normal;
  color:$black2;
  line-height: 25px;
  .payment-cancel-message {
    font-size: 24px;
  }
  // .payment-cancel-list {
  //   color:$black4;
  // }
  .payment-cancel-instructions{
    @include common-list;
    padding-left: 0.50em;
    text-indent: -0.15em;
    color:$black2;
    li {
      cursor: default !important;
      list-style-type: disc !important;
      content: "• ";
      // color: $gray3;
    }   
  }
  .payment-cancel-back-btn {
    font-family: "Gilroy Extrabold",sans-serif;
    font-weight: 800;
    font-size: 18px;
    color: $purple2;
    padding: 8px 90px !important;
    cursor: pointer !important;
    border-radius: 30px;
    border:1.5px solid $purple2;
  }
  .payment-cancel-proceed-btn {
    font-family: "Gilroy Extrabold",sans-serif;
    font-weight: 800;
    font-size: 18px;
    color: white !important;
    background-color: $purple2 !important;
    padding: 8px 69px;
    cursor: pointer !important;
    border-radius: 30px;
    border:1px solid $purple2;
  }
  .modal-dialog.cancel-modal {
    max-width: 750px !important;
  }
  @media only screen 
and (min-device-width : 490px) 
and (max-device-width : 1000px) 
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 2) { 
  #payment-cancel-proceed-btn-alignment{
   margin-left:12px !important;
   margin-top: 0px !important;
  }
}

}
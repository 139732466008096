@import "../variables.scss";
@import "../landing/mixins.scss";

//Landing-meetings-tab

.meetings {
  font-style: normal;
  font-weight: normal;  
  line-height: 29px; 
  .meetings-image, .meetings-list {
    cursor: default !important;
  }
  .meetings-tab-title {
      font-size: 25px;
      color: $black2;
    }
    .meetings-tab-border {
        color: $gray1;
        &.active {
          color: $purple1 !important;
          border-bottom-style: solid !important;
          border-bottom-width:medium !important;
          background-color:  transparent !important;
          border-color: $purple1 !important;
       }
    }
    .line-adjustment {
      margin-top: -9.5px;
    }
    .meetings-line {
      padding-bottom: 1%;
      border-bottom: 1px solid $gray3; 
    }
    .form-control-select-sm {
      border: 1px solid $white2;
      height: flex;
      width:flex;
      padding: 0.5rem 1rem;
      font-size:12px;
      line-height: 1.0;
      margin-bottom: 10px;
      border-radius: 20px;
   }
  .form-control-select-sm-black {
     border: 2px solid $black2;
     box-sizing: border-box;
     border-radius: 30px;
     height: flex;
     width:flex;
     padding: 0.3rem 0.3rem;
     font-size:12px;
     line-height: 1.0;
     margin-bottom: 10px;
     border-radius: 20px;
   }
  .form-control-select-sm-trans {
    height: 25px;
    width:flex;
    font-size:12px;
    line-height: 1.0;
    margin-bottom: 10px;
    border-radius: 8px;
    font-size: 10px;
   }
  .meetings-unordered-list {
    @include common-list;
  }
  .meetings-ending-line {
    padding-bottom: 1%;
    border-bottom: 1px solid $white3;    
    &:last-child {
      padding: none;
      border: none;    
     }
   }
  .meetings-timing-text {
    font-family: "Gilroy";
    font-weight: 800;
    color: $black2;
  }
  .meetings-start-btn {
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    cursor: pointer !important;
    font-family: "Gilroy Light";
    border-radius: 1.5rem;
    border:1px solid $purple1;
   }
   .schedule-meetings-main-heading ,.schedule-meetings-sub-heading {
    line-height: 23px;
    color: $black1;
   }
  .meetings-custom-checkbox {
    width:20px;
    height:20px;
  }
  .meetings-custom-checkbox-half {
    width:50px;
    height:30px;
  }
  .schedule-meetings-label {
    font-size: 14px;
    line-height: 20px;
    color: $black2;
  }
  .schedule-meetings-gilroy-label {
    font-family: "Gilroy",sans-serif;
    font-weight: 300;
    line-height: 18px;
    color: $black2;
    }
  .schedule-meetings-attendees-text {
    font-family: "Gilroy",sans-serif;
    font-size: 13px;
    line-height: 18px;
    color: $black1;
    }  
  .schedule-meetings-placeholder {
    font-family: "Gilroy",sans-serif;
    font-size: 13px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.2);
    }
  .schedule-meetings-option {
    font-family: "Gilroy",sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: $black2;
    }
  .schedule-meetings-id {
    font-family: "Gilroy",sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: $purple1;
    }
    
  .schedule-meetings-password {
    font-family: "Gilroy",sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: $black1;
    }
    .fs_10 {
      font-size: 10px;
    }
    .schedule-meeting-btn {
      border-radius: 30px;
      padding: 0.5rem 1.5rem;
      cursor: pointer !important;
      font-family: "Gilroy",sans-serif;
      font-weight: 800;
      font-size: 18px;
      line-height: 20px;
      color: #FFFFFF;
    }
    @media (max-width:576px) {
      .meetings-tab-title {
        font-size: 18px !important;
      }
      .line-adjustment {
        margin-top: -4px !important;
      }
    }
    @media (max-width:768px) {
      .meetings-tab-title {
        font-size: 20px;
      }
    }
  
    .meetings-gray-color {
      color: $gray1 !important;
    }
    .form-control::-webkit-input-placeholder {
      color:rgba(0, 0, 0, 0.2);
    }
    #birthdaytime{
      border-radius: 0.59rem !important;
    }
    .width_height_40 {
      width:40px !important;
      height:40px !important;
    }
  }
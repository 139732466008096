.footer-bs {
  background-color: #1f2449;
  padding: 30px 10px 10px 0px;
  color: #ffffff;
}

.footer-bs .footer-brand {
  padding-left: 50px;
}

.footer-bs .footer-brand h2 {
  margin: 0px 0px 10px;
}
.footer-bs .footer-brand p {
  font-size: 12px;
  color: #ffffff;
}

.footer-bs .footer-nav ul.pages {
  list-style: none;
  padding: 0px;
}
.footer-bs .footer-nav ul.pages li {
  color: #ffffff;
  font-size: 16px;
}

@media (min-width: 768px) {
  .footer-bs .footer-nav,
  .footer-bs .footer-social,
  .footer-bs .footer-ns {
    border-left: solid 1px #ffffff;
  }
}

@import "../variables.scss";

//Normal SCSS

.services-page {
    font-style: normal;
    font-weight: normal;
    color: $black4;
    .services-vertical-line {
        border-left: solid 2px $black4;
        padding: 0px 0px 0px 0px;
        opacity: 0.4;
      }
    .services-navigation-colour {
        background-color: $navigation-bg-color;
    }
    .services-white-bg-section {
        background-color: $common-white-bg-section;
    }
    .services-varied-white-bg-section {
        background-color:$common-varied-white-bg-color;
    }
    .services-benefits-bg-section {
        background-color: $benefits-section-bg-color;
    }
    .services-purple-bg-section {
        background-color: $purple5 !important;
    }
    .text-service {
        color: $gray5 !important;
    }
    .minimum-height-normal {
        min-height: $minimumheightnormal;
    }
    .minimum-height-small {
        min-height: $minimumheightsmall;
    }
    .minimum-height-medium {
        min-height: $minimumheightmedium;
    }
    .minimum-height-large{
        min-height: $minimumheightlarge;
    }
    .services-title {
        font-family: "Gilroy",sans-serif;
        font-size:26px;
        font-weight: 800 !important;
        line-height:32px;
    }
    .services-text {
        line-height: 25px;
        cursor: default !important;
    }
    .services-logo-header {
        font-weight: 300;
        line-height: 20px;
        color: $gray6;
    }
    .services-client-text {
        font-family: "Gilroy",sans-serif;
        font-weight: 800;
        font-size: 18px;
        line-height: 16px;
        color: $gray7;
      }
    .services-banking-text {
        line-height: 29px;
        color: $gray6 !important;
        li {
          cursor: default !important;
          content: "• ";
          color: $purple5; /* or whatever color you prefer */
      }
      }
    .services-list {
        cursor: default !important;
        list-style-type: none;
        padding:0;
        margin:0;
        color: $black4;
      }
    .services-indent{
        padding-left: 2.65em;
        text-indent: -2.65em;
    }
    .services-navigation {
        top: 83px !important;
    }
    .services-text-benefit {
        color: $blue1;
    }
   .services-buttons {
    .services-btn-text {
        font-family: "Gilroy",sans-serif;
        font-weight: 800;
        background: $white4;
        line-height: 20px;
        color: $purple4;
    }
    .services-btn-text-mobile {
        font-family: "Gilroy",sans-serif;
        font-weight: 800;
        background: $white4;
        font-size: 16px;
        line-height: 20px;
        color: $purple4;
    }
    .services-btn {
        padding:10px 65.5px;
        border: 2px solid $purple4;
        box-sizing: border-box;
    }
    .services-btn-mobile {
        padding-left: 3.0rem;
        padding-right: 3.0rem;
        border: 2px solid $purple4;
        box-sizing: border-box;
    }
    .services-btn-mobile-ui-ux {
        padding-left: 3.4rem;
        padding-right: 3.4rem;
        border: 2px solid $purple4;
        box-sizing: border-box;
    }
    .services-btn-edited-mulesoft {
        padding:10px 40px;
        border: 2px solid $purple4;
        box-sizing: border-box;
    }
    .services-btn-edited-mulesoft-mobile {
        padding-left:1.8rem;
        padding-right: 1.8rem;
        border: 2px solid $purple4;
        box-sizing: border-box;
    }
    .services-btn-edited-banking {
        padding:10px 33.5px;
        border: 2px solid $purple4;
        box-sizing: border-box;
    }
    .services-btn-edited-banking-mobile {
        padding-left:1.4rem;
        padding-right: 1.4rem;
        border: 2px solid $purple4;
        box-sizing: border-box;
    }
    .services-btn-text:hover {
          color:$purple4;
    }
    .services-decoration {
        text-decoration:none !important;
    }
           
   }

   .services-different {
    .services-list-title {
        font-weight: 800;
        font-size: 20px;
        line-height: 13px;
        cursor: default !important;
        color: $black4;
    }
    .services-list-margin {
        cursor: default !important;
        margin-left: 2.5rem !important;
    }
   }
   
   .services-mulesoft {
    .services-mulesoft-text {
        font-weight: 300;
        line-height: 22px;
      }
      .services-mulesoft-list-text {
          font-weight: 300;
          line-height: 22px;
          color: $black4;
          li {
            cursor: default !important;
            content: "• ";
          color: $purple5; 
          }
      }
      .services-horizontal-line {
          border: 1px solid rgba(0, 0, 0, 0.25);
          border-bottom: none;
      }
   }
}  
@media (min-width: 1025px) {
    .services-banner {
      background-image: url("../../stockimage.jpg");
      background-repeat: no-repeat !important;
      background-position-y: -45px !important;
      background-size:cover !important;
      min-height: 80.5vh !important; 
    }
}
@media screen and (max-width: 1025px) and (min-width: 768px) {
    .services-banner {
      background-image: url("../../stockimage.jpg");
      background-repeat: no-repeat !important;
    //   background-position-y: -0px !important;
      background-size: cover !important;
      min-height: 33vh !important; 
   }
}  
@media screen and (max-width: 767px) and  (min-width:250px) {
    .services-banner {
      background-image: url("../../stockimage.jpg");
      background-repeat: no-repeat !important;
      background-position-x: -9.7rem !important; 
      background-position-y: -1rem !important; 
      background-size: cover !important;
      min-height: 65vh !important; 
    }
}
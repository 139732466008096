@import "../variables.scss";

//Landing-recordings-tab

.recordings {
  font-style: normal;
  font-weight: normal;
  line-height: 29px; 
  color: $black1;
  .recordings-image {
    cursor: default !important;
  }
  .recordings-tab-title {
      font-size: 25px;
      color: $black2;
    }
    .recordings-border {
      color: $gray1;
      &.active {
        color: $purple1 !important;
        border-bottom-style: solid !important;
        border-bottom-width:medium !important;
        background-color:  transparent !important;
        border-color: $purple1 !important;
      }
    }
    .line-adjustment {
      margin-top: -9.5px;
    }
    .recordings-line {
      padding-bottom: 1%;
      border-bottom: 1px solid $gray3; 
    }
    .recordings-text {
      font-family: "Gilroy",sans-serif;
      font-weight: 300;
      font-size: 14px !important;
      line-height: 16px;
    }
    .progress-bar {
      background-color: $purple1 !important;
    }
    .progress {
      border-radius: 20px;
      height:8px;
      margin-top:3px; 
      width: 151px;
    }
    .recordings-settings-text {
      font-family: "Gilroy",sans-serif;
      font-weight: 300;
      font-size: 14px !important;
      color:$gray1;
    }
    .recordings-save-btn {
      border-radius: 30px;
      padding: 0.5rem 1.5rem;
      font-family: "Gilroy",sans-serif;
      font-weight: 800;
      font-size: 18px;
      line-height: 20px;
      color: #FFFFFF;
      cursor: pointer !important;
    }
    .table th,.table td {
      border:none;
      border-bottom: 1px solid $gray4;
    }
    .recordings-table-heading {
      font-family: "Gilroy" !important;  
      font-weight: 300;
      color: $gray1;
    }
    .table {
      border-collapse: collapse;
    }
    .table th,.table td {
      padding: 0.2rem 0.55rem; 
    }
    .recordings-table-content {
      font-family: "Gilroy" !important;  
      line-height: 27px;
      font-size: 14px;
    }
    @media (max-width:576px) {
      .recordings-tab-title {
        font-size: 18px !important;
      }
      .line-adjustment {
        margin-top: -4px !important;
      }
    }
    @media (max-width:768px) {
      .recordings-tab-title {
        font-size: 20px;
      }
    }
    .w_50 {
      width: 50% !important;
    }
    .w_40 {
      width: 40% !important;
    }
  }
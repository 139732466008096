/* support page header */

.support-page-header {
    &__title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
        
    }

    &__text {
        color: red;
    }
}

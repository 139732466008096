.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  font-family: "Gilroy Extrabold";
  line-height: 1.5;
  border-radius: 1.5rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-family: "Gilroy Light";
  line-height: 1.5;
  border-radius: 1.5rem;
}

.btn-default {
  color: #000;
  background-color: #ffffff;
  border-color: #ebe6f3;
  font-family: "Acherusgrotesque Regular";
}

.btn-default:hover {
  color: #000;
  background-color: #fff7f7;
  border-color: #ebe6f3;
}
.btn-default:focus,
.btn-default.focus {
  color: #000;
  background-color: #ffffff;
  border-color: #ebe6f3;
  box-shadow: 0 0 0 0.2rem rgba(161, 161, 163, 0.5);
}

.btn-default.disabled,
.btn-default:disabled {
  color: #000;
  background-color: #ebe6f3;
  border-color: #ebe6f3;
}
.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  color: #000;
  background-color: #fff5f5;
  border-color: #ebe6f3;
}

.btn-purple {
  color: #ffffff;
  background-color: #5d3ea8;
  border-color: #5008c4;
}

.btn-purple:hover {
  color: #ffffff;
  background-color: #5026b3;
  border-color: #5d0ddf;
}
.btn-purple:focus,
.btn-purple.focus {
  color: #ffffff;
  background-color: #4e2c9c;
  border-color: #590dd4;
  /* box-shadow: 0 0 0 0.15rem rgba(40, 9, 150, 0.5); */
}

.btn-purple.disabled,
.btn-purple:disabled {
  color: #ffffff;
  background-color: #5d3ea8;
  border-color: #590dd4;
}
.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #ffffff;
  background-color: #4e2c9c;
  border-color: #590dd4;
}
/* .btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 9, 150, 0.5);
} */

.btn-orange {
  color: #ffffff;
  background-color: #fa7603;
  border-color: #d6731c;
}

.btn-orange:hover {
  color: #ffffff;
  background-color: #e26d07;
  border-color: #d6731c;
}
.btn-orange:focus,
.btn-orange.focus {
  color: #ffffff;
  background-color: #e26d07;
  border-color: #d6731c;
  box-shadow: 0 0 0 0.15rem #fca457;
}

.btn-orange.disabled,
.btn-orange:disabled {
  color: #ffffff;
  background-color: #fc8e2d;
  border-color: #d6731c;
}
.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: #ffffff;
  background-color: #e26d07;
  border-color: #d6731c;
}

.btn-cancel{
  color: #5D3EA8;
  border-color:#5D3EA8;
  border-width: 0.15rem;
}
.btn-cancel:hover {
  color: #5D3EA8;
  border-color:#5D3EA8;
  border-width: 0.15rem;
}

.btn-back{
  color: #5D3EA8;
}
.btn-back:hover {
  color: #5D3EA8;
}

/*Pricing page button*/

.btn-toggle {
  top: 50%;
  transform: translateY(-50%);
}
.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #ffffff;
  background: #5D3EA7;
}
.btn-toggle:focus, .btn-toggle:focus.active, .btn-toggle.focus, .btn-toggle.focus.active {
  outline: none;
}
.btn-toggle:before, .btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-family: 'Gilroy',sans-serif;
  font-weight: 800;
  font-size: .75rem;
  letter-spacing: 0px;
  position: absolute;
  bottom: 0;
  transition: opacity .25s;
}
.btn-toggle:before {
  content: 'Monthly';
  left: -4rem;
}
.btn-toggle:after {
  content: 'Annually';
  right: -4rem;
  opacity: .5;
}
.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border:none;
  border-radius: 1.150rem;
  background: linear-gradient(0deg, #FFEBC3 150%, rgba(255, 235, 195, 0) 90%);
  transition: left .25s;
}
.btn-toggle.active {
  transition: background-color .25s;
}
.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left .25s;
}
.btn-toggle.active:before {
  opacity: .5;
}
.btn-toggle.active:after {
  opacity: 1;
}

.btn-toggle:before, .btn-toggle:after {
  color: #000000;
}
.btn-toggle.active {
  background-color: #5D3EA7;
}

@import "../variables.scss";

//Landing-image-properties
.landing-img-properties {
  position: relative;
  border-radius: 10px;
  transform: rotate(45deg);
  overflow: hidden;
  transform: matrix(0.54, 0.84, -0.85, 0.53, 0, 0);
}
.landing-img-properties > img {
  max-width: 100%;
  transform: rotate(-55deg) scaleX(1.6) scaleY(1.6);
}
.landing-img-properties:hover {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #fd7900 !important;
}

//Radio button size

.radio-btn { 
  transform: scale(1.25);
  display: inline-block; 
}

//Text-decoration

.text-decoration-underline {
  text-decoration: underline;
}

//SHOW AND HIDE DIV

.showDiv {
  display:block;
}
.hideDiv {
  display: none;
}
@import "../variables.scss";
 
.pricing{
    // Note: Do not give common line height
    font-style: normal;
    font-weight: normal;
    color: $black2;
    .pricing-card{
        background: #FFFFFF;
        border: 1px solid #EBEBEB;
        box-sizing: border-box;
        border-radius: 30px 30px 0px 0px;
        } 
    .pricing-heading{
        font-size: 42px;
    }
    .pricing-sub-heading{
        font-size: 24px;
    }
    #pricing-gradient{
        background: linear-gradient(180deg, #FFEBC3 -50%, rgba(255, 235, 195, 0) 100%);
        border-radius: 30px 0px 0px 0px;
        margin-left: -15px;
    }
    .pricing-purple-text{
        font-size: 24px;
        line-height: 34px;
        color: #5D3EA7;
    }
    .pricing-text {
        font-size: 18px;
        line-height: 25px;
        color: #000000;
    }
    .pricing-discount{
        font-family: 'Gilroy',sans-serif;
        font-weight: 800;
        font-size: 10px;
        line-height: 12px;
        color: #FFFFFF;
        border:6px solid #FB7900;
        background: #FB7900;
        border-radius: 6px;
    }
    .pricing-display{
        font-size: 44px;
        font-family:"Gilroy Extrabold";
    }
    .pricing-list ul{
        list-style-type: none;
        margin:0;
        padding:0;
    }
    .pricing-horizontal-line{
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-bottom: none;
        opacity: 0.3;
    }
    .pricing-vertical-line{
        border-left: solid 2px #b9b9b9;
        padding: 0px 0px 0px 20px;
        opacity: 0.3;
    }
    .pricing-info-bg{
        background: #FCBF49;
    }
    .pricing-info-text{
        font-size: 18px;
        line-height: 25px;
        color: #181818;
    }
    .contact-sales-btn{
        font-family: "Gilroy",sans-serif;
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
    }
    .showComponent{
        display:inline-block;
    }
    .hidecomponent{
        display:none;
    }
    #content1::before{
        content: "\a";
        white-space: pre;
    }
    /*Pricing Page Changes*/ 
    /* .pricing-list-text{
    font-family: 'Acherus Grotesque',sans-serif;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    } */
    @media (max-width: 576px) {
        #pricing-gradient{
          background:none;
        }
        #pricing-list-alignment{
          text-align: left;
        }
        #content1::before{
          display:none;
        }
    }

}
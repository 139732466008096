 //Variables

 $purple4:#5D3EA6;
 $purple1: #5D3EA7;
 $purple2: #5D3EA8; 
 $purple5: #5D3EA8;
 $orange1: #FB7900;
 $white1: #EEEEEE;
 $white2: #C5C5C5; 
 $white3: #D8D8E0;
 $white4: #f7f7f7;
 $transparent: transparent;
 $audio-input-bg: rgba(196,196,196,.9);
 $layout-bg-color: rgba(251, 121, 0, 0.11);
 $navigation-bg-color:#F7F9FC;
 $common-white-bg-section:#FFFFFF;
 $common-varied-white-bg-color:#F4F6F9;
 $sidebar-bg-color:rgba(255, 255, 255, 0.4);
 $benefits-section-bg-color:rgba(126, 204, 237, 0.15);
 $gray1:#6D6D74;
 $gray2: #535B5F;
 $gray3: #908C9A;
 $gray4:#C2BFCA;
 $gray5: #BEB2DC;
 $gray6: #4F4F4F;
 $gray7:#908C9A;
 $blue1:#2F3779;
 $black1: #000000;
 $black2: #333333;
 $black4: #333333;
 
 //COMMONLY USED FONTS IN SERVICES PAGE
 $ffagss: "Acherus Grotesque",sans-serif;
 $ffgss: "Gilroy",sans-serif;
 $ffg: "Gilroy";
 $ffag: "Acherus Grotesque";
 $ffgl:"Gilroy Light";
 $ffr:"Roboto";
 $ffhn:"HelveticaNeue";
 $ffhnss:"HelveticaNeue",sans-serif;
 
 //Min-Height
 $minimumheightnormal: 2vh;
 $minimumheightsmall: 4vh;
 $minimumheightmedium: 5vh;
 $minimumheightlarge: 10vh;
 
 //Border-radius
 $br_15: 1.5rem;
 $br_30: 30px;
 
 //line-height
 $lh_15: 1.5;
 
 //font-size
 $fs_875: 0.875rem;
 
 //font-weight
 $fw_8: 800;
 $fw_9: 900;
 
 //Breakpoints
 $mobile-breakpoint: 576px;
 $tablet-breakpoint: 767px;
 $middle-devices-breakpoint: 992px;
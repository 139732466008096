// Jaam new imports
@import "../variables.scss";
@import "../landing/mixins.scss";

.MuiSlider-markLabel {
  margin-left: -10%;
}

.landing-settings {
  .settings-title {
    font-size: 21px;
  }
 //  Jaam code starts
  .devicesettings {
    width: 155px;
    height: 29px;
    left: 458px;
    font-family: Acherus Grotesque;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
  }

  .video-container {
    border-radius: 20px;
  }

  .form-control-select-sm {
    border: 1px solid $white2;
    height: flex;
    width: flex;
    padding: 0.5rem 1rem;
    font-size: 12px;
    line-height: 1.0;
    margin-bottom: 10px;
    border-radius: 20px;
  }

  button[editext='edit-button'] {
    background: transparent;
    color: #000;
    border: none;
    outline: none;

    :focus-visible {
      outline: none;
    }

    :focus {
      outline: none;
    }
    border-color: transparent;
  }

  button[editext='cancel-button'] {
    background: transparent;
    color: #000;
    border: none;
    outline: none;

    :focus-visible {
      outline: none;
    }

    :focus {
      outline: none;
    }

    border-color: transparent;
  }

  button[editext='save-button'] {
    background: transparent;
    color: #000;
    border: none;
    outline: none;

    :focus-visible {
      outline: none;
    }

    :focus {
      outline: none;
    }

    border-color: transparent;
  }

  .btn:focus.landing-small-purple {
    box-shadow: none !important;
  }
  .audio-input-preview-level{
    width: 200px;
    margin-left: 50px;
    border-radius: 10px;
  }

  .btn{
    height: 36px;
    width: 90px;
    border-radius: 20px;
  }
  .cancel-btn{
    border: 1px solid #5D3EA8;
    height: 36px;
    width: 90px;
    border-radius: 20px;
  }
  .landing-small-purple {
    background-color: transparent !important;
    font-family: "Gilroy" !important;
    font-size: 14px !important;
    font-weight: 800 !important;
    border-color: transparent !important;
    border: none !important;
    box-shadow: none !important;

  }

  .landing-btn-sm-purple {
    border-radius: 20px;
}

  .landing-btn-sm {
    border-radius: 20px;
}

  .select-purple {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .schedule-meetings-placeholder {
    font-family: "Gilroy", sans-serif;
    font-size: 13px;
    line-height: 18px;
    color: rgba(48, 42, 42, 0.952);
    border-radius: 30px;
    border: 1px solid #ced4da;
  }

  .landing-btn-sm {
    position: absolute;
    left: 63.75%;
    right: 28.12%;
    top: 33.86%;
    bottom: 63.35%;
    width:117px;
    height: 42px;
    border: 1px solid #5D3EA8;
    box-sizing: border-box;
    border-radius: 30px;
  }
}
// Jaam code end

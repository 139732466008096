
.support-container-margin {
  margin-top: 70px;
}
.support-container-decoration {
  
  padding-bottom: 40px;
}
/* Rectangle 155 */
._support_banner{
    height: 70px;
    background: #5D3EA7;
    }
    /* Support Jaam */
    .supportjaam {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    margin-left:120px;
    line-height: 30px;
    color: rgb(252, 247, 247);
    /* identical to box height */
    color: #fbfafc;
    }
    /* Hello, Get support at anytime */
    .hello {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    color: #333333;
    }
    /* Rectangle 151 */
    .rectangle151 {
    height: 60px;
    width:100px;
    border: 1px solid #5D3EA7;
    box-sizing: border-box;
    border-radius: 50px;
    }
    /* How can we help? */
    .help {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height */
    
    color: rgba(14, 13, 13, 0.8);
    }
    /* magnifying-glass 1 */
    .glass {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 70px;
    top: 25px;
    
    
    }
    /* Rectangle 158 */
    .active_support{
    
        height: 110px;
        width:200px;
    background: rgba(142, 120, 193, 0.6);
    border: 5px solid rgba(93, 62, 166, 0.7);
    box-sizing: border-box;
    border-radius: 20px;
    
    }
    /* Rectangle 153 */
    .non_active_support{
    
        height: 110px;
        width:200px;
    background: #F6F3FF;
    border: 5px solid rgba(93, 62, 166, 0.7);
    box-sizing: border-box;
    border-radius: 20px;
    }
    .arrow_box {
      height: 110px;
        width:200px;
      position: relative;
      box-sizing: border-box;
      border-radius: 20px;
      background: rgba(142, 120, 193, 0.6);
      border: 4px solid rgba(93, 62, 166, 0.7);
    }
    .arrow_box:after, .arrow_box:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    
    .arrow_box:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #88b7d5;
      border-width: 30px;
      margin-left: -30px;
    }
    .arrow_box:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: rgba(93, 62, 166, 0.7);
      border-width: 36px;
      margin-left: -36px;
    }
    /* Rectangle 159 
    caret {
        position: relative;
      }
      
      .caret:before {
        content: '';
        position: absolute;
        top: 110px;
        left: 50px;
        border-top: 42px solid #e6dada;
        border-left: 42px solid transparent;
        border-right: 42px solid transparent;
      }
      
      .caret:after {
        content: '';
        position: absolute;
        left: 19px;
        top: 110;
        border-top: 23px solid #ffffff;
        border-left: 23px solid transparent;
        border-right: 23px solid transparent;
      }
    /* User guide */
    .userguide {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */
    color: #070707;
    }
    /* User Guide */
    .
    .list_label{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    
    /* identical to box height */
    color: #000000;
    }
    
    
    .list_head {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
   
    /* identical to box height */
    color: #000000;
    }
    /* How to join a meeting? */
    .list_sub_head{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  
    color: #000000;
    }
    /* There are two ways to join JAAM meeting from any device. */
    .list_para {
    text-align: justify;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  
    color: #000000;
    }
    /* Group 222 */
    /* Jaam - How to join a meeting? */
    .list_video
    {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #5D3EA6;
    }
    /* image 29 */
    .list_image {
    width: 300px;
    height: 250px;
    /*background: url(image.png);*/
    }
    /* User guide */
    /* image 35 */

/* Jaam - How to join a meeting? */

.video_text{
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 22px;
color: blueviolet;
}
/* FAQ */

.faq_header{
font-style: normal;
font-weight: normal;
font-size: 28px;
line-height: 35px;
/* identical to box height */
color: #5D3EA6;
}




    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
@import "../variables.scss";

// SIDEBAR 
.nav-link.active.GilroyExtrabold {
    background-color: transparent;
    border:none;
  }
   
  .sidebar-navigation {
     .sidebar-text {
        color: $purple1 !important; 
        padding-left: 10px;
        &.active {
          color: $black1 !important;
          border-left-style: solid !important;
          border-left-width: thick !important;
          background-color:  transparent !important;
          border-color: $purple1 !important;
        }
      }
      .sidebar-background {
        background: $sidebar-bg-color !important;
        padding-top: 150px;
        padding-left: 0px;
        padding-right: 10px;
        padding-bottom: 850px;
        margin-top: -130px;
        height:fit-content !important; 
      }
      @media(max-width: $mobile-breakpoint) {
        .sidebar-background {
          padding-top: 50px !important;
        }
      }
      .mobile-navigation {
          .btn-group {
            &.landing-mobile-nav {
              width:115%;
              margin-left:-1.8rem ;
            }
          }
          .btn-group {
            &.landing-mobile-nav {
              &:focus {
                width:115%;
                margin-left:-1.8rem ;
                border:none;
              }  
            }
          }
          .select-main-bg {
            background-color: $purple2;
            height: 50px !important;
          }
          .roboto-large-white {
            font-family: "Roboto" !important;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 18px !important;
            line-height: 21px;
            color: $white1 !important;
          }  
           .dropdown-menu {
              &.nav {
                 display: none !important;
                   &.show {
                     display:inline-block !important;
                     padding-right: 10px !important;
                     width: 94.9% !important;
                     height: 270px !important;
                     transform:translate3d(16px,47px,0px) !important;
                   }
                }
              }
              .caret {  
                padding-right:calc(15.9em - 6.9em) !important;
              }
           }
        }
  
  //Breakpoint changes
  
  @media screen and (min-width: $tablet-breakpoint) and (max-width: $middle-devices-breakpoint) {
   .sidebar-navigation {
      .sidebar-background {
        padding-left: 2% !important;
        background-color: transparent !important;
      }
    }
  }
    
  @media (max-width: $mobile-breakpoint) {
    .sidebar-navigation {
      .sidebar-background {
        background-color: transparent !important;
        padding-bottom: 0px !important;
        }
     } 
   }
@import "../variables.scss";
@import "../landing/mixins.scss";

//  LANDING-HOME-TAB-MEETINGS

.home {
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    color: $black2;
    .home-image, .home-list {
      cursor: default !important;
    }
    .home-tab-title {
      font-size: 25px;
    }
    .home-theme{
      color:$gray2 !important;
    }
    .home-user-name {
      font-family: "Gilroy" !important;
      font-size: 20px !important;
      font-weight:800 !important;
    }
    .home-user-email {
      font-size: 17px;
      color: $gray1;
    }
    .home-meeting-id,.home-passcode,.home-share-attendees,.home-require-pass,.home-meeting-question,.home-join-now-text {
      font-size: 17px;
    }
    .home-unordered-list {
      @include common-list;
    }
    .home-ending-line {
      padding-bottom: 1%;
      border-bottom: 1px solid $white3;    
      &:last-child {
        padding-bottom: 1%;
        border-bottom: 1px solid $gray3;    
       }
    }
    .home-timing-text {
      font-family: "Gilroy";
      font-weight: 800;
    }
    .home-start-btn {
      padding: 0.25rem 0.5rem;
      cursor: pointer !important;
      font-family: "Gilroy Light";
      border-radius: 1.5rem;
      border:1px solid $purple1;
    }
    .home-recorded-by-text {
      font-family: "Gilroy";
      font-weight: 300;
      font-size: 18px;
    }
    .font-size_18 {
      font-size: 18px;
    }
    .home-gray-color {
      color: $gray1 !important;
    }
    .br_50 {
      border-radius: 50px !important;
    }
    .btn:focus.home-save-edit-btn {
      box-shadow: none !important;
    }
    .home-save-edit-btn {
      background-color: transparent !important;
      font-family : "Gilroy" !important;
      font-size : 14px !important;
      font-weight: 800 !important;
      border-color:transparent !important;
      border: none !important;
    }
    .w_100 {
      width: 5.5vw;
    }
    .border_0 {
      border:0px;
    }
    .width_height_40 {
      width:40px !important;
      height:40px !important;
    }
    .width_height_60 {
      width:60px !important;
      height:60px !important;
    }
    .copy-to-clipboard-text {
      font-family : "Gilroy" !important;
      font-size : 14px !important;
      font-weight: 800 !important;
    }
    .home-start-meeting-text {
      font-family: "Gilroy";
      font-weight: 800;
      font-size : 36px !important;
      line-height: 44px;
      color : $gray2 !important;
    }
    div.password-alert>div.alert{
      padding: 0rem 0rem !important;
      margin-bottom: 0px !important;
    }
    div.password-alert>div.alert-danger {
      /* margin-left: -20px !important; */
      color:red !important;
      border:none !important;
      font-size: 13px !important;
      background-color: transparent !important;
    }
    div.password-alert>div.alert-success {
      color: transparent !important;
      border: none !important;
      background-color: transparent !important;
    }
  }
  @media screen and (max-width: 768px) and  (min-width:577px) {    
    .home {
        .home-tab-title {
            font-size: 20px;
        }
        .w_100 {
            width: 9.5vw !important;
        }
     }
  }
  @media (max-width:576px)
    {
      .home {
        .home-tab-title {
            font-size: 18px;
        }
     }
     .w_100 {
      width: 20vw !important;
   }
  }